<template>
  <div id="app" class="mx-auto flex max-w-md flex-col px-0 lg:px-6">
    <div class="flex">
      <img
        class="mx-auto my-4 h-16 w-auto"
        src="../assets/logo.jpeg"
        alt="PEV"
      />
      <img
        class="mx-auto my-4 h-16 w-auto"
        src="../assets/minsante.jpeg"
        alt="Minsante"
      />
    </div>
    <p class="w-full bg-neutral-500 py-1 text-center text-sm text-white">
      Bienvenue sur le Portail du Programme Elargi de Vaccination (PEV).
      Veuillez procéder à l'enregistrement :
    </p>
    <div
      v-if="isSuccess"
      class="flex flex-col items-center justify-center px-8 py-16"
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        style="color: #10b981"
        class="h-16 w-16"
        fill="none"
        viewBox="0 0 24 24"
        stroke="currentColor"
        stroke-width="1"
      >
        <path
          stroke-linecap="round"
          stroke-linejoin="round"
          d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z"
        />
      </svg>
      <p class="text-md mx-auto text-center text-gray-500">
        Merci pour votre enregistrement !
      </p>
      <div v-if="!isLoading" class="mb-4 flex p-6 text-center text-white">
        <button
          class="w-full rounded bg-green-700 p-2"
          @click="handleClickHome()"
        >
          Accueil
        </button>
      </div>
    </div>
    <div v-if="isSuiving" class="mb-4 flex p-6 text-center text-white">
      <button class="w-full rounded bg-green-700 p-2" @click="checkChild()">
        Suivi de l'enfant
      </button>
      <button
        class="ml-2 w-full rounded bg-blue-700 p-2"
        @click="handleClickRegister()"
      >
        Enregistrer un nouvel enfant
      </button>
    </div>
    <div>
      <form
        class="shadow-md"
        @submit.prevent="submitForm"
        style="background: aliceblue"
      >
        <div
          v-if="register"
          class="mb-4 rounded px-8 pb-8 pt-6 text-center shadow-md"
        >
          <div>
            <label class="">Entrer votre numéro de téléphone</label>
            <input
              id="phone"
              placeholder="Saisissez votre numéro de téléphone"
              v-model="form.phone"
              @input="validatePhoneNumber"
              class="rounded-2xl bg-white p-4 text-center shadow-xl"
            /><br />
          </div>
          <div class="mb-4 flex p-6 text-center text-white">
            <button
              class="w-full rounded bg-green-700 p-2"
              @click="checkPhone()"
            >
              Se connecter
            </button>
          </div>
          <LoaderComponent v-if="isLoading" />
        </div>
        <div
          v-if="childCheck"
          class="mx-12 mb-6 flex flex-col rounded-2xl border border-gray-300 px-4 py-4"
          style="background: aliceblue"
        >
          <p class="mb-4 text-center">Liste des enfants :</p>
          <div class="border-t border-gray-300"></div>
          <div v-if="!isLoading">
            <div v-for="(child, index) in children" :key="index">
              <div
                class="hover mt-1 flex items-center justify-between gap-2 py-2"
              >
                <label :for="'child' + child.id">
                  {{ letters[index] }}- {{ child.name }}
                </label>
                <div
                  class="flex items-center justify-end"
                  @click="
                    handleClickTown(child);
                    databaseOptions = child.vaccine_type;
                  "
                >
                  <svg
                    aria-hidden="true"
                    class="h-4 w-4"
                    fill="none"
                    stroke="currentColor"
                    stroke-width="2.5"
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M8.25 4.5l7.5 7.5-7.5 7.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    ></path>
                  </svg>
                </div>
              </div>
            </div>
          </div>
          <LoaderComponent v-else />
        </div>
        <div
          v-if="townCheck"
          class="mx-12 flex flex-col rounded-2xl border border-gray-300 px-4 py-4"
          style="background: aliceblue"
        >
          <p class="mb-4 text-center">Choisissez la ville :</p>
          <div class="border-t border-gray-300"></div>
          <div v-if="!isLoading">
            <!-- <label class="mb-2 text-sm text-gray-700" for="town">
              Entrez votre ville :
            </label> -->
            <select
              v-model="form.town"
              id="town"
              @change="onTownSuivingChange"
              class="mt-2 w-full rounded border-2 border-gray-200 px-2 py-1 text-gray-700"
              style="background-color: #e5e7eb"
            >
              <option disabled selected value="">Choisissez votre ville</option>
              <option value="Douala">Douala</option>
              <option value="Yaounde">Yaoundé</option>
            </select>
            <span
              class="text-[0.775rem] text-red-500"
              v-if="!formIsValid && form.town === ''"
              >veuillez renseigner votre ville</span
            >
            <div class="mb-4 flex p-6 text-center text-white">
              <button
                @click="handleClickDistrict"
                class="w-full rounded bg-green-700 p-2"
              >
                suivant
              </button>
            </div>
          </div>
          <LoaderComponent v-else />
        </div>
        <div
          v-if="districtCheck"
          class="mx-12 flex flex-col rounded-2xl border border-gray-300 px-4 py-4"
          style="background: aliceblue"
        >
          <p class="mb-4 text-center">Choisissez le district de la FOSA :</p>
          <div class="border-t border-gray-300"></div>
          <div v-if="!isLoading">
            <!-- <label class="mb-2 text-sm text-gray-700" for="district">
              Entrez votre ville :
            </label> -->
            <select
              v-model="form.district"
              id="district"
              class="mt-2 w-full rounded border-2 border-gray-200 px-2 py-1 text-gray-700"
              style="background-color: #e5e7eb"
            >
              <option disabled selected value="">
                Choisissez votre district
              </option>
              <option v-if="selectedTownSuiving === 'Douala'" value="Bonandjo">
                Bonandjo
              </option>
              <option v-if="selectedTownSuiving === 'Douala'" value="New-Bell">
                New-Bell
              </option>
              <option v-if="selectedTownSuiving === 'Douala'" value="Logbaba">
                Logbaba
              </option>
              <option v-if="selectedTownSuiving === 'Douala'" value="Bonassama">
                Bonassama
              </option>
              <option
                v-if="selectedTownSuiving === 'Douala'"
                value="Bonamoussadi"
              >
                Bonamoussadi
              </option>
              <option v-if="selectedTownSuiving === 'Douala'" value="Manoka">
                Manoka
              </option>
              <option v-if="selectedTownSuiving !== 'Douala'" value="Nlongkak">
                Nlongkak
              </option>
              <option v-if="selectedTownSuiving !== 'Douala'" value="Tsinga">
                Tsinga
              </option>
              <option v-if="selectedTownSuiving !== 'Douala'" value="Efoulan">
                Efoulan
              </option>
              <option v-if="selectedTownSuiving !== 'Douala'" value="Kondengui">
                Kondengui
              </option>
              <option v-if="selectedTownSuiving !== 'Douala'" value="Essos">
                Essos
              </option>
              <option
                v-if="selectedTownSuiving !== 'Douala'"
                value="Biyem-Assi"
              >
                Biyem-Assi
              </option>
              <option
                v-if="selectedTownSuiving !== 'Douala'"
                value="Nkolbisson"
              >
                Nkolbisson
              </option>
            </select>
            <span
              class="text-[0.775rem] text-red-500"
              v-if="!formIsValid && form.district === ''"
              >veuillez renseigner votre district</span
            >
            <div class="mb-4 flex p-6 text-center text-white">
              <button
                @click="handleClickCenter"
                class="w-full rounded bg-green-700 p-2"
              >
                suivant
              </button>
            </div>
          </div>
          <LoaderComponent v-else />
        </div>
        <div
          v-if="centerCheck"
          class="mx-12 flex flex-col rounded-2xl border border-gray-300 px-4 py-4"
          style="background: aliceblue"
        >
          <p class="mb-4 text-center">Entrez le centre de santé :</p>
          <div class="border-t border-gray-300"></div>
          <div v-if="!isLoading">
            <input
              type="text"
              v-model="form.health_center"
              id="health_center"
              class="w-full rounded-md border px-3 py-2 placeholder-gray-500 focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
            />
            <div class="mb-4 flex p-6 text-center text-white">
              <button
                @click="handleClickVaccine"
                class="w-full rounded bg-green-700 p-2"
              >
                suivant
              </button>
            </div>
          </div>
          <LoaderComponent v-else />
        </div>
        <div
          v-if="vaccineCheck"
          class="mx-12 flex flex-col rounded-2xl border border-gray-300 px-4 py-4"
          style="background: aliceblue"
        >
          <p class="mb-4 text-center">Choisissez votre visite</p>
          <div class="border-t border-gray-300"></div>
          <div>
            <select
              v-model="form.vaccine_type"
              id="vaccine_type"
              class="mt-2 w-full rounded border-2 border-gray-200 px-2 py-1 text-gray-700"
              style="background-color: #e5e7eb"
            >
              <option disabled selected value="">
                Choisisser votre visite
              </option>
              <option
                v-for="option in filteredOptionsVaccine"
                :key="option.value"
                :value="option.value"
              >
                {{ option.text }}
              </option>
            </select>
          </div>
          <div v-if="!isLoading" class="mt-2 w-full text-center">
            <button
              type="submit"
              class="form-medium mb-4 rounded bg-blue-600 px-5 py-2.5 text-sm text-white focus:ring-0"
              @click="updateSubmit()"
              style="background-color: #0a52f2"
            >
              Enregistrer
            </button>
          </div>
          <LoaderComponent v-else />
        </div>
        <div v-if="isFormula">
          <SectionComponent>
            <label
              class="float-left mb-2 block text-sm text-gray-700"
              for="name"
            >
              Entrez votre nom :
            </label>
            <input
              v-model="form.name"
              class="focus:shadow-outline w-full appearance-none rounded border px-2 py-1 leading-tight focus:outline-none"
              style="background-color: #e5e7eb"
              id="name"
              type="text"
            />
            <span
              class="text-[0.775rem] text-red-500"
              v-if="!formIsValid && form.name === ''"
              >veuillez renseigner votre nom</span
            >
          </SectionComponent>
          <SectionComponent>
            <label
              class="float-left mb-2 block text-sm text-gray-700"
              for="name"
            >
              Entrez le nom de l'enfant :
            </label>
            <input
              v-model="form.child_name"
              class="focus:shadow-outline w-full appearance-none rounded border px-2 py-1 leading-tight focus:outline-none"
              style="background-color: #e5e7eb"
              id="name"
              type="text"
            />
            <span
              class="text-[0.775rem] text-red-500"
              v-if="!formIsValid && form.child_name === ''"
              >veuillez renseigner le nom de votre enfant</span
            >
          </SectionComponent>
          <SectionComponent>
            <label
              class="float-left mb-2 block text-sm text-gray-700"
              for="phone"
            >
              Entrez votre numéro de téléphone :
            </label>
            <div class="flex w-full items-center">
              <span class="bg-green-200 px-2 py-1 text-sm">+237</span>
              <input
                v-model="form.phone"
                class="focus:shadow-outline w-full appearance-none px-2 py-1 leading-tight focus:outline-none"
                style="background-color: #e5e7eb"
                id="phone"
                minlength="9"
                type="number"
              />
            </div>
            <span
              class="text-[0.775rem] text-red-500"
              v-if="!formIsValid && form.phone === ''"
              >veuillez renseigner votre numéro de téléphone</span
            >
          </SectionComponent>
          <SectionComponent>
            <label
              class="float-left mb-2 block text-sm text-gray-700"
              for="phone"
            >
              Entrez un deuxième numéro de téléphone :
            </label>
            <div class="flex w-full items-center">
              <span class="bg-green-200 px-2 py-1 text-sm">+237</span>
              <input
                v-model="form.other_phone"
                class="focus:shadow-outline w-full appearance-none px-2 py-1 leading-tight focus:outline-none"
                style="background-color: #e5e7eb"
                id="phone"
                minlength="9"
                type="number"
              />
            </div>

            <span
              class="text-[0.775rem] text-red-500"
              v-if="!formIsValid && form.other_phone === ''"
              >veuillez un deuxième numérode téléphone</span
            >
          </SectionComponent>
          <SectionComponent>
            <label class="mb-2 text-sm text-gray-700" for="town">
              Entrez votre ville :
            </label>
            <select
              v-model="form.town"
              id="town"
              @change="onTownChange"
              class="mt-2 w-full rounded border-2 border-gray-200 px-2 py-1 text-gray-700"
              style="background-color: #e5e7eb"
            >
              <option disabled selected value="">Choisissez votre ville</option>
              <option value="Douala">Douala</option>
              <option value="Yaounde">Yaoundé</option>
            </select>
            <span
              class="text-[0.775rem] text-red-500"
              v-if="!formIsValid && form.town === ''"
              >veuillez renseigner votre ville</span
            >
          </SectionComponent>
          <SectionComponent>
            <label class="mb-2 block text-sm text-gray-700" for="district">
              Entrez votre district :
            </label>
            <select
              v-model="form.district"
              id="district"
              class="mt-2 w-full rounded border-2 border-gray-200 px-2 py-1 text-gray-700"
              style="background-color: #e5e7eb"
            >
              <option disabled selected value="">
                Choisissez votre district
              </option>
              <option v-if="selectedTown === 'Douala'" value="Bonandjo">
                Bonandjo
              </option>
              <option v-if="selectedTown === 'Douala'" value="New-Bell">
                New-Bell
              </option>
              <option v-if="selectedTown === 'Douala'" value="Logbaba">
                Logbaba
              </option>
              <option v-if="selectedTown === 'Douala'" value="Bonassama">
                Bonassama
              </option>
              <option v-if="selectedTown === 'Douala'" value="Bonamoussadi">
                Bonamoussadi
              </option>
              <option v-if="selectedTown === 'Douala'" value="Manoka">
                Manoka
              </option>
              <option v-if="selectedTown !== 'Douala'" value="Nlongkak">
                Nlongkak
              </option>
              <option v-if="selectedTown !== 'Douala'" value="Tsinga">
                Tsinga
              </option>
              <option v-if="selectedTown !== 'Douala'" value="Efoulan">
                Efoulan
              </option>
              <option v-if="selectedTown !== 'Douala'" value="Kondengui">
                Kondengui
              </option>
              <option v-if="selectedTown !== 'Douala'" value="Essos">
                Essos
              </option>
              <option v-if="selectedTown !== 'Douala'" value="Biyem-Assi">
                Biyem-Assi
              </option>
              <option v-if="selectedTown !== 'Douala'" value="Nkolbisson">
                Nkolbisson
              </option>
            </select>
            <span
              class="text-[0.775rem] text-red-500"
              v-if="!formIsValid && form.district === ''"
              >veuillez renseigner votre district</span
            >
          </SectionComponent>
          <SectionComponent>
            <label class="mb-2 block text-sm text-gray-700" for="health_center">
              Entrez votre centre de santé :
            </label>
            <input
              v-model="form.health_center"
              class="focus:shadow-outline mt-2 w-full appearance-none rounded border px-3 py-2 leading-tight focus:outline-none"
              style="background-color: #e5e7eb"
              id="health_center"
              type="text"
            />
            <span
              class="text-[0.775rem] text-red-500"
              v-if="!formIsValid && form.health_center === ''"
              >veuillez entrer votre centre de santé</span
            >
          </SectionComponent>
          <SectionComponent>
            <label class="text-sm text-gray-700" for="answer">
              Vaccin pour :
            </label>
            <select
              v-model="form.answer"
              id="answer"
              class="mt-2 w-full rounded border-2 border-gray-200 px-2 py-1 text-gray-700"
              style="background-color: #e5e7eb"
            >
              <option disabled selected value="">
                Selectionner une categorie :
              </option>
              <option value="Enfant">Enfant</option>
            </select>
            <span
              class="text-[0.775rem] text-red-500"
              v-if="!formIsValid && form.answer === ''"
              >Selectionner une categorie
            </span>
          </SectionComponent>
          <SectionComponent>
            <label class="mb-2 text-sm text-gray-700" for="vaccine_type">
              Choisissez votre visite
            </label>
            <select
              v-model="form.vaccine_type"
              id="vaccine_type"
              @change="handleChangeVaccine"
              class="mt-2 w-full rounded border-2 border-gray-200 bg-white px-2 py-1 text-gray-700"
              style="background-color: #e5e7eb"
            >
              <option disabled selected value="">Selectionner la visite</option>
              <option value="1er contact">1er contact</option>
              <option value="2ieme contact">2ieme contact</option>
              <option value="3ieme contact">3ieme contact</option>
              <option value="4ieme contact">4ieme contact</option>
              <option value="5ieme contact">5ieme contact</option>
              <option value="6ieme contact">6ieme contact</option>
              <option value="7ieme contact">7ieme contact</option>
              <option value="8ieme contact">8ieme contact</option>
              <option value="9ieme contact">9ieme contact</option>
              <option value="10ieme contact">10ieme contact</option>
              <option value="11ieme contact">11ieme contact</option>
            </select>
            <span
              class="text-[0.775rem] text-red-500"
              v-if="!formIsValid && form.vaccine_type === ''"
              >Selectionner une categorie
            </span>
          </SectionComponent>
          <div v-if="!isLoading" class="mt-2 w-full text-center">
            <button
              type="submit"
              class="form-medium mb-4 rounded bg-blue-600 px-5 py-2.5 text-sm text-white focus:ring-0"
              style="background-color: #0a52f2"
            >
              Enregistrer
            </button>
          </div>
          <LoaderComponent v-else />
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import "../assets/radio.css";
import SectionComponent from "@/components/SectionComponent.vue";
import LoaderComponent from "@/components/LoaderComponent.vue";

export default {
  components: { LoaderComponent, SectionComponent },
  data() {
    return {
      options: [
        { value: "A la naissance", label: "A la naissance", parent: "Enfant" },
        { value: "1 mois et demi", label: "1 mois et demi", parent: "Enfant" },
        { value: "2 mois et demi", label: "2 mois et demi", parent: "Enfant" },
        { value: "3 mois et demi", label: "3 mois et demi", parent: "Enfant" },
        { value: "6 mois", label: "6 mois", parent: "Enfant" },
        { value: "9 ans", label: "9 ans", parent: "Adolescents" },
        { value: "Td1", label: "Td1", parent: "Femme enceinte non vaccinée" },
        { value: "Td2", label: "Td2", parent: "Femme enceinte non vaccinée" },
        { value: "Td3", label: "Td3", parent: "Femme enceinte non vaccinée" },
        { value: "Td4", label: "Td4", parent: "Femme enceinte non vaccinée" },
        { value: "Td5", label: "Td5", parent: "Femme enceinte non vaccinée" },
        { value: "Td1", label: "Td1", parent: "Femme enceinte vaccinée" },
        { value: "Td2", label: "Td2", parent: "Femme enceinte vaccinée" },
        { value: "Td3", label: "Td3", parent: "Femme enceinte vaccinée" },
        { value: "Covid-19", label: "Covid-19", parent: "Adultes" },
      ],
      filteredOptions: [],
      children: [],
      letters: [
        "a",
        "b",
        "c",
        "d",
        "e",
        "f",
        "g",
        "h",
        "i",
        "j",
        "k",
        "l",
        "m",
        "n",
        "o",
        "p",
        "q",
        "r",
        "s",
        "t",
        "u",
        "v",
        "x",
        "y",
        "z",
      ],
      isSuccess: false,
      isFormula: false,
      phoneCheck: false,
      childCheck: false,
      register: false,
      isSuiving: false,
      verify: false,
      healthCheck: false,
      vaccineCheck: false,
      districtCheck: false,
      townCheck: false,
      centerCheck: false,
      isLoading: false,
      showInput: false,
      formIsValid: true,
      form: {
        phone: "",
        other_phone: "",
        town: "",
        name: "",
        child_name: "",
        health_center: "",
        district: "",
        vaccine_type: "",
        answer: "",
      },
      selectedTown: "",
      selectedTownSuiving: "",
      availableOptions: [
        { value: "1er contact", text: "1er contact" },
        { value: "2ieme contact", text: "2ieme contact" },
        { value: "3ieme contact", text: "3ieme contact" },
        { value: "4ieme contact", text: "4ieme contact" },
        { value: "5ieme contact", text: "5ieme contact" },
        { value: "6ieme contact", text: "6ieme contact" },
        { value: "7ieme contact", text: "7ieme contact" },
        { value: "8ieme contact", text: "8ieme contact" },
        { value: "9ieme contact", text: "9ieme contact" },
        { value: "10ieme contact", text: "10ieme contact" },
        { value: "11ieme contact", text: "11ieme contact" },
      ],
      databaseOptions: [],
      //selectedChild: [],
    };
  },
  computed: {
    filteredOptionsVaccine() {
      console.log(this.databaseOptions);
      if (Array.isArray(this.databaseOptions)) {
        return this.availableOptions.filter(
          (option) =>
            !this.databaseOptions.some((dbOption) => option.value <= dbOption)
        );
      } else {
        return this.availableOptions.filter(
          (option) => option.value > this.databaseOptions
        );
      }
    },
  },
  methods: {
    onTownChange() {
      this.selectedTown = this.form.town;
    },
    onTownSuivingChange() {
      this.selectedTownSuiving = this.form.town;
    },

    validatePhoneNumber() {
      let regex = /^[0-9]*$/;
      if (!regex.test(this.form.phone)) {
        this.form.phone = this.form.phone.replace(/\D/g, "").toString();
      }
    },

    checkPhone() {
      if (this.form.phone !== "") {
        this.register = false;
        this.isLoading = true;
        console.log("form data here: ", this.form);
        axios
          .post(
            `${process.env.VUE_APP_API_BASE_URL}/phoneExist/` + this.form.phone
          )
          .then((response) => {
            console.log(response.data);
            if (response.data.status === "OK") {
              this.isLoading = false;
              this.isSuiving = true;
              console.log(response.data.data);
            } else {
              this.isLoading = false;
              this.isFormula = true;
              this.form.message = "Aucun enfant enregistré !";
            }
          });
      } else {
        alert("veuillez entrer votre numéro de téléphone");
      }
    },

    checkChild() {
      console.log("form data here: ", this.form);
      this.isLoading = true;
      axios
        .post(
          `${process.env.VUE_APP_API_BASE_URL}/phoneExist/` + this.form.phone
        )
        .then((response) => {
          console.log(response.data);
          this.isLoading = false;
          this.childCheck = true;
          this.children = response.data.data.children;
          this.isFormula = false;
          console.log(response.data.data);
        });
    },

    updateSubmit() {
      this.form.child_id = this.selectedChildId;
      this.healthCheck = true;
      console.log(this.form);
      this.isLoading = true;
      const requestData = {
        vaccine_type: this.form.vaccine_type,
        child_id: this.form.child_id,
        health_center: this.form.health_center,
        district: this.form.district,
        town: this.form.town,
      };
      axios
        .post(`${process.env.VUE_APP_API_BASE_URL}/child-update`, requestData)
        .then((response) => {
          console.log(response);
          this.isLoading = false;
          this.isSuccess = true;
          this.vaccineCheck = false;
          this.isSuiving = false;
        })
        .catch(() => {
          this.isLoading = false;
        });
    },

    submitForm(event) {
      event.preventDefault();
      const url = new URL(window.location.href.replace("#/", ""));
      const mode = new URLSearchParams(url.search).get("mode");
      if (
        this.form.phone !== "" &&
        this.form.other_phone !== "" &&
        this.form.town !== "" &&
        this.form.name !== "" &&
        this.form.health_center !== "" &&
        this.form.district !== "" &&
        this.form.vaccine_type !== "" &&
        this.form.answer !== ""
      ) {
        this.isLoading = true;
        this.form.lang = this.$i18n.locale;
        if (mode) {
          this.form.mode = mode;
        }
        console.log("_________________" + this.form);
        const payload = {
          ...this.form,
          phone: this.form.phone.toString(),
          other_phone: this.form.other_phone.toString(),
        };
        axios
          .post(`${process.env.VUE_APP_API_BASE_URL}/client-create`, payload)
          .then((response) => {
            console.log("****************" + this.form);
            this.isSuccess = response.data.data.is_verified ?? true;
            this.isNotVerified = !response.data.data.is_verified ?? true;
            this.isLoading = false;
            this.isFormula = false;
            this.isSuiving = false;
          })
          .catch(() => {
            this.isLoading = false;
          });
      } else {
        this.formIsValid = false;
      }
    },

    handleChangeVaccine() {
      //this.form.answer = "";
      this.filteredOptions = this.options
        .slice()
        .filter((option) => option.parent === this.form.vaccine_type);
    },
    handleClickRegister() {
      this.isFormula = true;
      this.register = false;
      this.childCheck = false;
    },
    handleClickVaccine() {
      this.vaccineCheck = true;
      this.childCheck = false;
      this.townCheck = false;
      this.centerCheck = false;
      this.register = false;
      this.isSuiving = false;
    },
    handleClickCenter() {
      this.centerCheck = true;
      this.childCheck = false;
      this.districtCheck = false;
      this.register = false;
      this.isSuiving = false;
    },
    handleClickTown(child) {
      this.selectedChildId = child.id;
      this.townCheck = true;
      this.centerCheck = false;
      this.childCheck = false;
      this.districtCheck = false;
      this.register = false;
      this.isSuiving = false;
    },
    handleClickDistrict() {
      this.townCheck = false;
      this.centerCheck = false;
      this.childCheck = false;
      this.districtCheck = true;
      this.register = false;
      this.isSuiving = false;
    },
    handleClickHome() {
      this.isSuccess = false;
      this.register = true;
      this.townCheck = false;
      this.centerCheck = false;
      this.childCheck = false;
      this.districtCheck = false;
    },
  },
  mounted() {
    this.register = true;
    this.phoneCheck = false;
    this.isFormula = false;
    this.healthCheck = false;
    this.vaccineCheck = false;
    this.childCheck = false;
  },
};
</script>
